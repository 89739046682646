import React, { useCallback, useEffect, useState } from "react"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

const GallerySection = ({pictures}) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [photos, setPhotos] = useState(null)

  useEffect(() => {
    getImages()
  }, [])

  const getImages = async () => {
    const data = await Promise.all(
      pictures.map(async item => {
        const img = new Image()

        item.thumbnail = item.localFile ? item.localFile.childImageSharp.gatsbyImageData.images.fallback.src : item.node.image[0].localFile.childImageSharp.gatsbyImageData.images.fallback.src
        item.src = item.localFile ? item.localFile.childImageSharp.gatsbyImageData.images.fallback.src : item.node.image[0].localFile.childImageSharp.gatsbyImageData.images.fallback.src
        item.srcSet =  item.localFile ? item.localFile.childImageSharp.gatsbyImageData.images.fallback.srcSet : item.node.image[0].localFile.childImageSharp.gatsbyImageData.images.fallback.srcSet
        img.src = item.localFile ? item.localFile.childImageSharp.gatsbyImageData.images.fallback.src : item.node.image[0].localFile.childImageSharp.gatsbyImageData.images.fallback.src
        await img.decode()
        const { height, width } = calculateRatio(img.height, img.width)
        item.height = height
        item.width = width
        item.alt = item.localFile ? item.key : item.node.title
        item.className = "gallery-image"
        return item
      })
    )
    setPhotos(data)
  }

  const calculateRatio = (height, width) => {
    for (var num = height; num > 1; num--) {
      if (width % num === 0 && height % num === 0) {
        width = width / num
        height = height / num
      }
    }
    return { height, width }
  }

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  },[])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  return (
    <>
    {photos && photos.length > 0 && (
      <div>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal open={true} onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    )}
  </>
  )
}



export default GallerySection

